import echartsSet from '@/utils/echartsSet.js'
// import mockEchartsData from '@/utils/mockEchartsData.js'
// import moduleComonMixin from './moduleCommonMixin'
const blueGradientBarColor = ['#24C1E8','#5DACF6']//蓝色渐变柱图
const greenGradientBarColor = ["#1DC9B6","#3DC9E2"]//绿色渐变柱图
export default {
    props:{
        title:'',//模块标题
    },
    data(){
        return {
            echartsSet,
            ttyid:[],

            // mockEchartsData
            baseChartsObj:{
                title:'7天平均记录',
                xAxisData:["分类1", "分类2", "分类2", "分类4", "分类5", "分类6"],
                chartData: [
                    {
                        name: '进水口污泥浓度',
                        unit: '%',
                        value:[],
                        color: blueGradientBarColor,
                        type: 'line'
                    },
                    {
                        name: '出水口污泥浓度',
                        unit: '个',
                        value:[],
                        color: greenGradientBarColor,
                        type: 'line'
                    },
                ]
            },
        }
    },
    mounted(){   
        setTimeout(()=>{
        this.sty()
    },2000)
    },

    methods: {

        sty(){

            let tay = eval("(" + this.$store.getters.getDemojson + ")")
            let tab = tay.data.record
            console.log(tay.data.record)
            let objKeys = Object.keys(tab);
            this.baseChartsObj.xAxisData = [tab[0].date,tab[1].date,tab[2].date,tab[3].date,tab[4].date,tab[5].date,tab[6].date] //时间分类
            let bbac = [parseFloat(tab[0].data1).toFixed(1),parseFloat(tab[1].data1).toFixed(1),parseFloat(tab[2].data1).toFixed(1),parseFloat(tab[3].data1).toFixed(1),parseFloat(tab[4].data1).toFixed(1),parseFloat(tab[5].data1).toFixed(1),parseFloat(tab[6].data1).toFixed(1)] //
            let bbab = [parseFloat(tab[0].data4).toFixed(1),parseFloat(tab[1].data4).toFixed(1),parseFloat(tab[2].data4).toFixed(1),parseFloat(tab[3].data4).toFixed(1),parseFloat(tab[4].data4).toFixed(1),parseFloat(tab[5].data4).toFixed(1),parseFloat(tab[6].data4).toFixed(1)] //
           this.baseChartsObj.chartData[0].value.push(parseFloat(tab[0].data1).toFixed(1),parseFloat(tab[1].data1).toFixed(1),parseFloat(tab[2].data1).toFixed(1),parseFloat(tab[3].data1).toFixed(1),parseFloat(tab[4].data1).toFixed(1),parseFloat(tab[5].data1).toFixed(1),parseFloat(tab[6].data1).toFixed(1))
           this.baseChartsObj.chartData[1].value.push(parseFloat(tab[0].data4).toFixed(1),parseFloat(tab[1].data4).toFixed(1),parseFloat(tab[2].data4).toFixed(1),parseFloat(tab[3].data4).toFixed(1),parseFloat(tab[4].data4).toFixed(1),parseFloat(tab[5].data4).toFixed(1),parseFloat(tab[6].data4).toFixed(1))
             console.log(bbac,bbab)
             console.log(this.baseChartsObj.chartData[0].value)
            Object.keys(objKeys).forEach((item,key) =>{
                
                let date = tab[item].date
                this.ttyid.push({
                    date
                })
            })
        }

    }

}