<template>

    <module :title="title">
        <div class="mt_8">
            <img src="@/assets/533.jpg" width="100%" height="100%" alt=""/>
            
        </div>

<el-descriptions :title="'更新时间：'+time_id" direction="vertical" :column="2" border>
  <el-descriptions-item label="设备编号" label-class-name="my-label" content-class-name="my-content">{{id}}</el-descriptions-item>
  <el-descriptions-item label="进水口污泥浓度(mg/L)">{{form_data1}}</el-descriptions-item>
  <el-descriptions-item label="出口污泥浓度(mg/L)">{{form_data4}}</el-descriptions-item>
  <el-descriptions-item label="瞬时流量(m³/h)">{{form_data7}}</el-descriptions-item>
  <el-descriptions-item label="信号强度">{{sig}}</el-descriptions-item>
  <el-descriptions-item label="备注"></el-descriptions-item>
</el-descriptions>
        <div class="item-box--block charts">
            <Echarts :options="opt" />
        </div>
    </module>
</template>

<script>
import moduleComonMixin from './moduleCommonMixin'
import Vue from 'vue';
// import { mapState } from 'vuex'
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  Backtop,
  PageHeader,
  CascaderPanel,
  Loading,
  MessageBox,
  Message,
  Notification
} from 'element-ui';

// Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
// Vue.prototype.$loading = Loading.service;
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
// Vue.prototype.$message = Message;
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(PageHeader);
Vue.use(CascaderPanel);

Vue.use(Loading.directive);



export default {

    name: 'baseChart',
    mixins:[moduleComonMixin],
    data(){
        return {
            id:"",
            time_id:"",
            form_data1:"",
            form_data4:"",
            form_data7:"",
            sig:"",
            opt:{},
            datajson:[],
        }
    },
    created(){
        // new.$emit('getisshow',this.$route.query.code);
        console.log(this.$route.query.code)
        localStorage.setItem("demoVal",this.$route.query.code);
        this.$store.commit('setDemoValue', this.$route.query.code);
    },
    mounted(){
        setTimeout(()=>{
            this.tty()
        },2000)
    },
    methods: {
        tty(){
        let chartOpt = this.baseChartsObj
        this.opt = this.echartsSet.baseChartsOpt(chartOpt)
        this.datajson = eval("(" + this.$store.getters.getDemojson + ")")
        this.id = this.datajson.data.last.code  //设备ID
        this.time_id = this.datajson.data.last.create_time //更新时间列表
        this.form_data1 = parseFloat(this.datajson.data.last.data1).toFixed(1)
        this.form_data4 = parseFloat(this.datajson.data.last.data4).toFixed(1) 
        this.form_data7 = parseFloat(this.datajson.data.last.data7).toFixed(1)  
        this.sig = this.datajson.data.last.signal
        }
    }
}

</script>

<style lang="scss" scoped>
</style>
